var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-device-configurator" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "b-checkbox",
            {
              attrs: { switch: "" },
              model: {
                value: _vm.useSMS,
                callback: function($$v) {
                  _vm.useSMS = $$v
                },
                expression: "useSMS"
              }
            },
            [_vm._v("Send via SMS")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.runnerTimer,
                  expression: "runnerTimer"
                }
              ],
              staticClass: "running-indicator"
            },
            [
              _c("b-spinner", [_vm._v("Spinner")]),
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("Running Commands...")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.deviceList
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "bv-table",
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.deviceList,
                  fields: _vm.deviceFields,
                  "th-class": "bv-table-header"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(selected)",
                      fn: function(row) {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAll,
                              callback: function($$v) {
                                _vm.selectAll = $$v
                              },
                              expression: "selectAll"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(selected)",
                      fn: function(row) {
                        return [
                          _c("b-form-checkbox", {
                            on: {
                              change: function($event) {
                                return _vm.selectChange(row.item)
                              }
                            },
                            model: {
                              value: row.item.selected,
                              callback: function($$v) {
                                _vm.$set(row.item, "selected", $$v)
                              },
                              expression: "row.item.selected"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "head(setupOptionIdx)",
                      fn: function(row) {
                        return [
                          _c(
                            "b-dropdown",
                            { attrs: { text: "Setup Commands" } },
                            [
                              _c(
                                "b-dropdown-header",
                                { attrs: { id: "dropdown-header-label" } },
                                [_vm._v(" Set all to: ")]
                              ),
                              _vm._l(_vm.setupCommandOptions, function(
                                commandOption
                              ) {
                                return _c(
                                  "b-dropdown-item-button",
                                  {
                                    key: commandOption.value,
                                    on: {
                                      click: function($event) {
                                        return _vm.setAllSetupCommands(
                                          commandOption.value
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(commandOption.text) + " "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c("i", {
                            staticClass: "fa fa-cogs icon-button-warning",
                            on: {
                              click: function($event) {
                                return _vm.$bvModal.show("modal-device-setup")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(setupOptionIdx)",
                      fn: function(row) {
                        return [
                          _c("b-form-select", {
                            attrs: { options: _vm.setupCommandOptions },
                            model: {
                              value: row.item.setupOptionIdx,
                              callback: function($$v) {
                                _vm.$set(row.item, "setupOptionIdx", $$v)
                              },
                              expression: "row.item.setupOptionIdx"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(row) {
                        return [
                          _vm.runningCommands &&
                          _vm.runningCommands[row.item.device_imei]
                            ? _c(
                                "div",
                                {
                                  staticClass: "command-results",
                                  class: {
                                    "status-complete":
                                      _vm.runningCommands[row.item.device_imei]
                                        .status === "complete",
                                    "status-error":
                                      _vm.runningCommands[row.item.device_imei]
                                        .status === "error"
                                  }
                                },
                                _vm._l(
                                  _vm.runningCommands[row.item.device_imei]
                                    .commandSet,
                                  function(command, idx) {
                                    return _c("div", { key: idx }, [
                                      command.status === "queued"
                                        ? _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-circle-o row-icon-preview",
                                            attrs: {
                                              title:
                                                "Queued: " + command.command
                                            }
                                          })
                                        : _vm._e(),
                                      command.status === "waiting"
                                        ? _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-circle-o row-icon-preview",
                                            attrs: {
                                              title:
                                                "Waiting: " +
                                                command.type +
                                                " " +
                                                command.command
                                            }
                                          })
                                        : _vm._e(),
                                      command.status === "OK"
                                        ? _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-check-circle row-icon-preview",
                                            attrs: {
                                              title:
                                                "OK: " +
                                                command.type +
                                                " " +
                                                command.command
                                            }
                                          })
                                        : _vm._e(),
                                      command.status === "error"
                                        ? _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-times-circle-o row-icon-preview",
                                            attrs: {
                                              title: "Error: " + command.command
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fa fa-list-alt row-icon-preview",
                            attrs: { title: "Command History" },
                            on: {
                              click: function($event) {
                                return _vm.showCommandHistory(row.item)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3633904677
                )
              }),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hideExecute,
                      expression: "!hideExecute"
                    }
                  ],
                  staticClass: "button",
                  on: { click: _vm.clickExecute }
                },
                [_vm._v("Execute Commands")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-device-setup",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Device Setup Command Sets"
          },
          on: { hide: _vm.saveSetupCommands }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _vm._v(" Setup Commands Sets: "),
                _c("b-form-select", {
                  attrs: { options: _vm.setupCommandOptions, "select-size": 8 },
                  model: {
                    value: _vm.setupCommandSelect,
                    callback: function($$v) {
                      _vm.setupCommandSelect = $$v
                    },
                    expression: "setupCommandSelect"
                  }
                }),
                _c("b-button", { on: { click: _vm.setupCommandAdd } }, [
                  _vm._v("Add")
                ]),
                _c("b-button", { on: { click: _vm.setupCommandRemove } }, [
                  _vm._v("Remove")
                ])
              ],
              1
            ),
            _vm.setupCommandSelect !== null
              ? _c(
                  "div",
                  { staticClass: "col-11 ml-5 mt-2" },
                  [
                    _vm._v(" Set Name: "),
                    _c("b-input", {
                      model: {
                        value: _vm.getSetupCommand(_vm.setupCommandSelect).text,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.getSetupCommand(_vm.setupCommandSelect),
                            "text",
                            $$v
                          )
                        },
                        expression: "getSetupCommand(setupCommandSelect).text"
                      }
                    }),
                    _vm._v(" Command List: "),
                    _c(
                      "draggable",
                      {
                        staticClass: "command-container",
                        attrs: {
                          draggable: ".command-item",
                          group: { name: "commands" }
                        },
                        model: {
                          value: _vm.getSetupCommand(_vm.setupCommandSelect)
                            .commands,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.getSetupCommand(_vm.setupCommandSelect),
                              "commands",
                              $$v
                            )
                          },
                          expression:
                            "getSetupCommand(setupCommandSelect).commands"
                        }
                      },
                      [
                        _c("div", { staticClass: "command-item-header" }, [
                          _c("div", { staticClass: "col-1" }, [
                            _vm._v(" Order ")
                          ]),
                          _c("div", { staticClass: "col-3" }, [
                            _vm._v(" Type ")
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(" Details ")
                          ])
                        ]),
                        _vm._l(
                          _vm.getSetupCommand(_vm.setupCommandSelect).commands,
                          function(command, idx) {
                            return _c(
                              "div",
                              {
                                key: command.value,
                                staticClass: "command-item"
                              },
                              [
                                _c("div", { staticClass: "col-1" }, [
                                  _vm._v(" #" + _vm._s(idx) + " ")
                                ]),
                                _c("div", { staticClass: "col-3" }, [
                                  _vm._v(" " + _vm._s(command.type) + " ")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-6 justify-content-around"
                                  },
                                  [
                                    _vm._v(" " + _vm._s(command.text) + " "),
                                    _c("i", {
                                      staticClass: "row-icon-button-danger",
                                      class: _vm.$config.icons.general.remove,
                                      on: {
                                        click: function($event) {
                                          return _vm.removeCommandText(command)
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          }
                        )
                      ],
                      2
                    ),
                    _c(
                      "b-input-group",
                      { attrs: { size: "sm" } },
                      [
                        _c(
                          "b-input-group-prepend",
                          [
                            _c("b-select", {
                              attrs: { options: _vm.commandTypes },
                              model: {
                                value: _vm.currentCommandType,
                                callback: function($$v) {
                                  _vm.currentCommandType = $$v
                                },
                                expression: "currentCommandType"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.currentCommandType === "Command"
                          ? _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$bvModal.show(
                                      "modal-command-help"
                                    )
                                  }
                                }
                              },
                              [_vm._v("Commands")]
                            )
                          : _vm._e(),
                        _vm.currentCommandType === "WaitFor"
                          ? _c("b-select", {
                              attrs: { options: _vm.waitTypes },
                              model: {
                                value: _vm.waitType,
                                callback: function($$v) {
                                  _vm.waitType = $$v
                                },
                                expression: "waitType"
                              }
                            })
                          : _vm._e(),
                        _c("b-form-input", {
                          staticClass: "text-input",
                          attrs: {
                            type: "text",
                            id: "filterInput",
                            placeholder: "Enter Command Text to Add",
                            debounce: "500"
                          },
                          nativeOn: {
                            keydown: function($event) {
                              return _vm.keydownHandler.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.currentCommandText,
                            callback: function($$v) {
                              _vm.currentCommandText = $$v
                            },
                            expression: "currentCommandText"
                          }
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: { click: _vm.addCommandText }
                              },
                              [_vm._v("Add")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-device-setup")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-command-help",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Device Setup Command Sets"
          }
        },
        [
          _c("device-command-help", {
            attrs: { "modal-id": "modal-command-help" },
            on: { change: _vm.onCommandTextChange }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-command-history",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Device Command History"
          }
        },
        [_c("admin-command-device", { attrs: { device: _vm.currentDevice } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }