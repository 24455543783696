<template>
  <div class="admin-device-configurator">
    <div class="toolbar">
      <b-checkbox switch v-model="useSMS">Send via SMS</b-checkbox>
      <div class="running-indicator" v-show="runnerTimer">
        <b-spinner >Spinner</b-spinner>
        <span class="ml-1">Running Commands...</span>
      </div>

    </div>
      <div v-if="deviceList">
        <b-table striped hover :items="deviceList" :fields="deviceFields"
                 th-class="bv-table-header"
                 class="bv-table">
          <!--   Select All Header             -->
          <template v-slot:head(selected)="row">
            <b-form-checkbox v-model="selectAll" v-on:change="toggleSelectAll"></b-form-checkbox>
          </template>
          <!--Select Checkbox Row Element-->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox v-model="row.item.selected" v-on:change="selectChange(row.item)"></b-form-checkbox>
          </template>
          <!--              Setup Commands-->
          <template v-slot:head(setupOptionIdx)="row">
            <b-dropdown text="Setup Commands">
              <b-dropdown-header id="dropdown-header-label">
                Set all to:
              </b-dropdown-header>
              <b-dropdown-item-button v-for="commandOption of setupCommandOptions" :key="commandOption.value" @click="setAllSetupCommands(commandOption.value)">
                {{commandOption.text}}
              </b-dropdown-item-button>
            </b-dropdown>
            <i class="fa fa-cogs icon-button-warning" @click="$bvModal.show('modal-device-setup')"></i>
          </template>
          <template v-slot:cell(setupOptionIdx)="row">
            <b-form-select v-model="row.item.setupOptionIdx" :options="setupCommandOptions"></b-form-select>
<!--            <b-dropdown text="Setup Commands">-->
<!--              <b-dropdown-header id="dropdown-header-label">-->
<!--                Set all to:-->
<!--              </b-dropdown-header>-->
<!--              <b-dropdown-item-button v-for="commandOption of setupCommandOptions" :key="commandOption.value" @click="setAllSetupCommands(commandOption.value)">-->
<!--                {{commandOption.text}}-->
<!--              </b-dropdown-item-button>-->
<!--            </b-dropdown>-->
          </template>
          <template v-slot:cell(status)="row">
            <div v-if="runningCommands && runningCommands[row.item.device_imei]"
                 :class="{
              'status-complete': runningCommands[row.item.device_imei].status === 'complete',
              'status-error' : runningCommands[row.item.device_imei].status === 'error'
            }"
            class="command-results"
            >
              <div v-for="(command, idx) of runningCommands[row.item.device_imei].commandSet" :key="idx">
                <i class="fa fa-circle-o row-icon-preview" v-if="command.status==='queued'"
                   v-b-tooltip.hover :title="'Queued: ' + command.command"></i>
                <i class="fa fa-circle-o row-icon-preview" v-if="command.status==='waiting'"
                   v-b-tooltip.hover :title="'Waiting: ' + command.type + ' ' + command.command"></i>
                <i class="fa fa-check-circle row-icon-preview" v-if="command.status==='OK'"
                   v-b-tooltip.hover :title="'OK: '+ command.type + ' ' + command.command"></i>
                <i class="fa fa-times-circle-o row-icon-preview" v-if="command.status==='error'"
                   v-b-tooltip.hover :title="'Error: ' + command.command"></i>
              </div>
            </div>
            <i class="fa fa-list-alt row-icon-preview"
               v-b-tooltip.hover title="Command History" @click="showCommandHistory(row.item)"></i>
          </template>
        </b-table>
        <b-button class="button" @click="clickExecute" v-show="!hideExecute">Execute Commands</b-button>
      </div>

    <!--    Setup Command Builder Modal Template-->
    <b-modal id="modal-device-setup" centered class="modal-content" size="xl" hide-footer title="Device Setup Command Sets" @hide="saveSetupCommands">
      <div class="row">
        <div class="col-4">
          Setup Commands Sets:
          <b-form-select v-model="setupCommandSelect" :options="setupCommandOptions" :select-size="8"></b-form-select>
          <b-button @click="setupCommandAdd">Add</b-button>
          <b-button @click="setupCommandRemove">Remove</b-button>
        </div>
        <div class="col-11 ml-5 mt-2" v-if="setupCommandSelect !== null">
          Set Name: <b-input v-model="getSetupCommand(setupCommandSelect).text"></b-input>
          Command List:
          <draggable class="command-container" v-model="getSetupCommand(setupCommandSelect).commands" draggable=".command-item" :group="{ name: 'commands'}" >
            <div class="command-item-header">
              <div class="col-1">
                Order
              </div>
              <div class="col-3">
                Type
              </div>
              <div class="col-6">
                Details
              </div>
            </div>
            <div v-for="(command, idx) of getSetupCommand(setupCommandSelect).commands" :key="command.value" class="command-item">
              <div class="col-1">
                #{{idx}}
              </div>
              <div class="col-3">
                {{command.type}}
              </div>
              <div class="col-6 justify-content-around">
                {{command.text}}
                <i class="row-icon-button-danger" :class="$config.icons.general.remove" @click="removeCommandText(command)"></i>
              </div>

            </div>
          </draggable>
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-select :options="commandTypes" v-model="currentCommandType"></b-select>
            </b-input-group-prepend>
<!--            Command Type-->
              <b-button
                v-if="currentCommandType==='Command'"
                @click="$bvModal.show('modal-command-help')">Commands</b-button>
<!--            Delay Type-->
            <b-select
              v-if="currentCommandType==='WaitFor'"
              :options="waitTypes"
              v-model="waitType"
            ></b-select>
            <b-form-input
              v-model="currentCommandText"
              type="text"
              id="filterInput"
              placeholder="Enter Command Text to Add"
              debounce="500"
              class="text-input"
              @keydown.native="keydownHandler"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="addCommandText" variant="danger">Add</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-button class="button" @click="$bvModal.hide('modal-device-setup')">Close</b-button>
    </b-modal>

    <!--Command Help Modal-->
    <b-modal id="modal-command-help" centered class="modal-content" size="xl" hide-footer title="Device Setup Command Sets">
      <device-command-help modal-id="modal-command-help" @change="onCommandTextChange"></device-command-help>
    </b-modal>

    <!--Device Command History Modal-->
    <b-modal id="modal-command-history" centered class="modal-content" size="xl" hide-footer title="Device Command History">
      <admin-command-device :device="currentDevice"></admin-command-device>
    </b-modal>
  </div>
</template>

<script>
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as AlertHelper from '../../components/helpers/AlertHelper'
import DeviceCommandHelp from './device_command_types/DeviceCommandHelp'
import draggable from 'vuedraggable'
import AdminCommandDevice from '@/components/admin/AdminCommandDevice'
export default {
  name: 'admin-device-configurator',
  components: {
    AdminCommandDevice,
    draggable,
    DeviceCommandHelp
  },
  props: [ 'devices', 'hideExecute' ],
  data: function () {
    return {
      deviceList: [
      ],
      deviceFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'imei',
          label: 'IMEI #',
          sortable: true
        },
        { key: 'device_phone',
          label: 'Phone #',
          sortable: true
        },
        {
          key: 'setupOptionIdx',
          label: 'Setup Commands'
        },
        {
          key: 'status',
          label: 'Status'
        }
      ],
      selectAll: false,
      setupCommandOptions: [
        {
          text: 'None',
          value: null,
          type: 'None'
        }
      ],
      setupCommandSelect: null,
      currentCommand: null,
      currentCommandText: '',
      currentCommandType: 'Command',
      userId: 0,
      userSettings: {},
      runningCommands: {
        0: {
          command: 'loading',
          status: 'queued',
          sendResponse: null,
          value: 0
        }
      },
      runnerTimer: null,
      currentDevice: null,
      useSMS: false,
      commandTypes: [
        'Command',
        'WaitFor'
      ],
      waitTypes: [
        'Seconds',
        'MessageWith'
      ],
      waitType: 'Seconds',
      waitValue: '0'
    }
  },
  created () {
    this.userId = JSON.parse(localStorage.getItem('userid'))
    this.loadSetupCommands()
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    clickExecute: function () {
      console.log('Click Execute')
      this.runningCommands = {}
      this.deviceList.forEach(device => {
        if (device.setupOptionIdx) {
          let commandSet = this.setupCommandOptions[device.setupOptionIdx].commands.map(x => {
            return {
              type: x.type, // Type of command
              command: x.text, // Command text/parameters
              status: 'queued', // Status
              sendResponse: null, // Response Data
              value: 0 // temp value storage (i.e. timestamps)
            }
          })
          this.$set(this.runningCommands, device.device_imei, {
            status: 'queued',
            progress: 0,
            commandSet: commandSet
          })
        }
      })
      console.log('Running Commands:', this.runningCommands)
      this.startRunner(0)
    },
    startRunner: function (delay) {
      this.runnerTimer = setTimeout(() => {
        this.commandRunnerTick()
      }, delay)
    },
    stopRunner: function () {
      clearTimeout(this.runnerTimer)
      this.runnerTimer = null
    },
    commandRunnerTick: async function () {
      let incompleteQueues = 0
      let errorQueues = 0
      for (let imei in this.runningCommands) {
        let queue = this.runningCommands[imei]
        if (queue.status === 'queued') {
          incompleteQueues += 1
          for (let command of queue.commandSet) {
            // Loop Through the command queue and take action as appropriate
            // Next command is ready to be sent.
            if (command.status === 'queued') {
              if (command.type === 'Command') {
                await this.queueHandlerCommand(imei, command)
              } else if (command.type === 'WaitForSeconds') {
                await this.queueHandlerWaitForSecondsStart(imei, command)
              } else if (command.type === 'WaitForMessageWith') {
                await this.queueHandlerWaitForResponseStart(imei, command)
              }
              break
            } else if (command.status === 'waiting') {
              if (command.type === 'WaitForSeconds') {
                await this.queueHandlerWaitForSecondsCheck(imei, command)
              } else if (command.type === 'WaitForMessageWith') {
                await this.queueHandlerWaitForResponseCheck(imei, command)
              }
              break
            // This command was sent, but is waiting for a response still.
            } else if (command.status === 'OK') {
              // TODO - Add command receipt checking
              if (queue.commandSet.indexOf(command) === (queue.commandSet.length - 1)) {
                // If we're at the end of the queue, mark is as complete
                queue.status = 'complete'
              }
            // If we find an error in the command queue, stop.
            } else if (command.status === 'error') {
              queue.status = 'error'
              break
            }
          }
        } else if (queue.status === 'error') {
          errorQueues += 1
        }
      }
      console.log('commandRunnerTick: ', this.runningCommands)
      if (incompleteQueues) {
        this.startRunner(1000)
      } else {
        if (errorQueues > 0) {
          ErrorHelper.displayGeneralErrorToast('One or more commands did not execute successfully!', 'Uh Oh...')
        } else {
          AlertHelper.successToast('Your commands were sent successfully!', 'Things went well')
        }
        this.stopRunner()
      }
    },
    queueHandlerCommand: async function (imei, command) {
      let method = this.useSMS ? 'sms' : 'data'
      let resp = await DataProvider.sendDeviceCommands(imei, command.command, method)
      command.sendResponse = resp
      if (resp.success) {
        command.status = 'OK'
      } else {
        command.status = 'error'
      }
    },
    queueHandlerWaitForSecondsStart: async function (imei, command) {
      command.value = Date.now() + (parseInt(command.command) * 1000)
      command.status = 'waiting'
    },
    queueHandlerWaitForSecondsCheck: async function (imei, command) {
      if (Date.now() > command.value) {
        command.status = 'OK'
      }
    },
    queueHandlerWaitForResponseStart: async function (imei, command) {
      command.status = 'waiting'
      command.value = (Date.now() - 1000) / 1000 // remove 1 second in case command response was very quick
    },
    queueHandlerWaitForResponseCheck: async function (imei, command) {
      let history = await DataProvider.getDeviceCommands(imei)
      if (history.success) {
        let matchedCommand = history.data.some(x =>
          x.timestamp >= command.value && x.direction === 'from_device' && x.text.includes(command.command)
        )
        if (matchedCommand) {
          command.status = 'OK'
        }
      } else {
        ErrorHelper.displayDataErrorToast(history)
      }
    },
    toggleSelectAll: function (event) {
      if (event) {
        this.deviceList.forEach(x => { x.selected = true })
      } else {
        this.deviceList.forEach(x => { x.selected = false })
      }
    },
    selectChange: function (user) {
      this.selectAll = false
    },
    // Create a new unique ID for an optionlist
    createUniqueId: function (optionList) {
      let exists = true
      let id = optionList.length
      while (exists) { // Find a unique ID. Otherwise the select element will behave oddly
        exists = optionList.find(x => x.value === id) !== undefined
        if (exists) {
          id++
        }
      }
      return id
    },
    setupCommandAdd: function () {
      let id = this.createUniqueId(this.setupCommandOptions)
      this.setupCommandOptions.push({
        value: id,
        text: 'New Commands ' + id,
        commands: []
      })
    },
    setupCommandRemove: function () {
      console.log('remove: ', this.setupCommandSelect)
      if (this.setupCommandSelect !== null) {
        // let removedCommand = this.setupCommandSelect
        let idx = this.setupCommandOptions.findIndex((x) => x.value === this.setupCommandSelect)
        this.setupCommandOptions.splice(idx, 1)
        this.setupCommandSelect = null
      }
    },
    getSetupCommand: function (value) {
      return this.setupCommandOptions.find((x) => x.value === value)
    },
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand()
      }
    },
    onCommandTextChange: function (command) {
      console.log(command)
      this.currentCommandText = command.command
    },
    addCommandText: function () {
      if (this.currentCommandText.length > 0) {
        let id = this.createUniqueId(this.getSetupCommand(this.setupCommandSelect).commands)
        if (this.currentCommandType === 'Command') {
          this.getSetupCommand(this.setupCommandSelect).commands.push({
            text: this.currentCommandText,
            value: id,
            type: this.currentCommandType
          })
        } else {
          if (this.waitType === 'Seconds' && isNaN(parseInt(this.currentCommandText))) {
            ErrorHelper.displayGeneralErrorToast('Wait time supplied is not a number!', 'Enter a Number')
            return
          }
          this.getSetupCommand(this.setupCommandSelect).commands.push({
            text: this.currentCommandText,
            value: id,
            type: this.currentCommandType + this.waitType
          })
        }
      }
    },
    removeCommandText: function (command) {
      let idx = this.getSetupCommand(this.setupCommandSelect).commands.indexOf(command)
      this.getSetupCommand(this.setupCommandSelect).commands.splice(idx, 1)
    },
    loadSetupCommands: async function () {
      let response = await DataProvider.getUserSettings(this.userId)
      this.setupCommandOptions = [{
        text: 'None',
        value: null
      }]
      if (response.success) {
        if (response.data.hasOwnProperty('deviceSetupCommandSets')) {
          this.userSettings = response.data
          this.setupCommandOptions = this.setupCommandOptions.concat(response.data.deviceSetupCommandSets)
        }
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
      console.log('Setup Commands: ', this.setupCommandOptions)
    },
    saveSetupCommands: async function () {
      console.log('saved command')
      // localStorage.setItem('deviceSetupCommandSets', JSON.stringify(this.setupCommandOptions))
      // this.userSettings.deviceSetupCommandSets = this.setupCommandOptions
      let savedCommands = this.setupCommandOptions.filter(x => x.text !== 'None')
      let response = await DataProvider.setUserSettings({ deviceSetupCommandSets: savedCommands })
      if (!response.success) {
        ErrorHelper.displayDataErrorToast(response)
        ErrorHelper.displayGeneralErrorToast('There was a problem saving your device command settings!', 'Error Saving Settings')
      }
      console.log(response)
    },
    setAllSetupCommands: function (id) {
      this.deviceList.forEach(device => { this.$set(device, 'setupOptionIdx', id) })
    },
    showCommandHistory: function (device) {
      this.currentDevice = device
      this.$bvModal.show('modal-command-history')
    }
  },
  watch: {
    devices: function (newVal, oldVal) {
      this.deviceList = newVal
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-device-configurator {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    font-size: 1rem;
    align-items: center;
    margin-bottom: 1vh;
  }

  .icon-button-warning {
    margin: 0;
    padding: 0;
  }

  .command-container {
    background: $theme-color-background-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 5rem;
  }

  .command-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2A9B00;
    margin: 5px 10px;
    padding: 5px;
    border-radius: 5px;
    line-height: 1.2;
  }

  .command-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $theme-color-background-1;
    margin: 5px 10px;
    padding: 5px;
    line-height: 1.2;
    font-weight: 600;
    border-bottom: 1px solid $theme-color-primary-3;
  }

  .running-indicator {
    color: $theme-color-primary-2;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: 5%;
  }

  .command-results{
    display: flex;
    border-radius: 15px;
    border: 1px solid #07b307;
    padding: 1px;
    justify-content: space-around;
  }

  .status-complete {
    color: #20e720;
  }

  .status-error {
    color: red;
    border: 1px solid red;
  }

</style>
